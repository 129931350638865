import { inject, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BasePaymentService } from '../../http/base-payment.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { UntypedFormGroup } from '@angular/forms';
import { PAYMENT_API, PAYMENT_PRAXIS_HPF_SESSION } from '../../http/base-http.service';
import { combineLatest, concatMap, distinctUntilChanged, from, map, Observable, switchMap, take } from 'rxjs';
import { HOST_NAME } from 'src/app/global.tokens';
import { LoginStatusService } from '../../auth/login/login-status.service';
import { AngularFirestore, CollectionReference, DocumentData, Query } from '@angular/fire/compat/firestore';


export interface DepositDetailsResponse {
  fields: {

    label?: string,
    name: string,
    special_hide?: string,
    special_select?: string,
    tooltip?: string,
    type: string,
    value?: string,
    input_type?,
    hide_when_select?
    "force_default_option_value"?,
    "hidden"?,
    "user_field_value"?,
    "disabled"?,
    "hide_option_add_account"?,
    "deposit_extra_description"?,
    "require_currency_exchange"?,
    "deposit_no_amount"?,

    choices?: {
      value: number, display_name: string, visible_fields: null, selected: boolean,
      credit_card_holder?, month?, year?, hide_when_select?
    }[]
  }[],
  name: string
  pk?: number,
  limits?
  currency?
  bonuses?
  freespins?
  deposit_extra_description?
  require_currency_exchange?
  "deposit_no_amount"?,
}

export type DepositDetailsDB = {
  id: string,
  deposit_date: Date,
  deposit_value: number,
}

const mockObj: DepositDetailsResponse = { "pk": 74, "name": "PAYMENT VOUCHER", "limits": { "maximum": 2500, "minimum": 10, "currency_name": "EUR" }, "currency": "EUR", "bonuses": [{ "pk": 105, "name": "CRM_reload_WO", "amount": "100.00", "code": "WB2_NEW_PLN" }, { "pk": 230, "name": "AFF_100%streamer_1w", "amount": "100.00", "code": "100%streamer_1w" }, { "pk": 232, "name": "AFF_100%streamer_2w", "amount": "100.00", "code": "100%streamer_2w" }, { "pk": 233, "name": "AFF_200%streamer_1w", "amount": "200.00", "code": "200%streamer_1w" }, { "pk": 234, "name": "AFF_200%streamer_2w", "amount": "200.00", "code": "200%streamer_2w" }, { "pk": 318, "name": "VIP_reload_100%JUL", "amount": "100.00", "code": "VIP_reload_100%JUL" }, { "pk": 319, "name": "CRM_reload_HolidayCity", "amount": "100.00", "code": "CRM_reload_HolidayCity" }], "freespins": [], "fields": [{ "name": "amount", "choices": [{ "value": 25, "display_name": "25", "visible_fields": null, "selected": null, "credit_card_holder": null, "month": null, "year": null }, { "value": 50, "display_name": "50", "visible_fields": null, "selected": null, "credit_card_holder": null, "month": null, "year": null }, { "value": 100, "display_name": "100", "visible_fields": null, "selected": null, "credit_card_holder": null, "month": null, "year": null }, { "value": 200, "display_name": "200", "visible_fields": null, "selected": null, "credit_card_holder": null, "month": null, "year": null }], "type": "amount", "label": "amount", "input_type": null, "value": null, "hide_when_select": null, "special_hide": null, "special_select": null, "tooltip": null, "force_default_option_value": null, "hidden": false, "user_field_value": null, "disabled": false, "hide_option_add_account": false }], "deposit_extra_description": null, "require_currency_exchange": true, "deposit_no_amount": false }

@Injectable({
  providedIn: 'root'
})
export class DepositService extends BasePaymentService {

  constructor(http: HttpClient, cookie: CookieService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale, hostName);
    this.loginStatusService = inject(LoginStatusService);
    this.afs = inject(AngularFirestore);
  }

  loginStatusService: LoginStatusService;
  afs: AngularFirestore;

  public getDepositDetails(paymentId: string): Observable<DepositDetailsResponse> {
    const url = PAYMENT_API + paymentId

    return super.get<DepositDetailsResponse>(url);

  }
  public getPraxisHpfSession(): Observable<{ session_token: string }> {
    return super.get<{ session_token: string }>(PAYMENT_PRAXIS_HPF_SESSION);

  }


  public postDeposit(depositForm: UntypedFormGroup): Observable<{ payment_link: string }> {
    const url = PAYMENT_API + depositForm.get("payment_id").value
    return super.postRequest<{ payment_link: string }>(url, depositForm);
  }

  public postAdditionalDeposit(paymentId: number | string, additionalFieldsForm: UntypedFormGroup): Observable<{ payment_link: string }> {
    const url = PAYMENT_API + paymentId;
    return super.postRequest<{ payment_link: string }>(url, additionalFieldsForm);
  }

  public getDepositDetail(user): Observable<DepositDetailsDB[]> {
    return this.afs.collection('prize-users').doc(user.username).collection('deposit-entries', ref => {
      let query: CollectionReference | Query<DocumentData> = ref;
      query = query.where("is_acknowledged_front", "==", 0).orderBy('deposit_date', "desc")
      return query
    }).snapshotChanges().pipe(
      distinctUntilChanged(),
      map(resp => {
        const respArray = []
        resp.forEach((doc) => {
          // doc.payload.doc.ref.delete().then(() => { })
          const docData = doc.payload.doc.data();
          respArray.push({ id: doc.payload.doc.id, ...docData })
        });
        return respArray;
      })
    )
  }


}
